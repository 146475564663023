<template>
  <div class="screen" :class="{'no-swipe': swipeDisabled}">
    <div class="screen__description">
      <h2>Start Free Trial</h2>
    </div>
    <div class="features">
      <ul>
        <li>Any number of members in your circles</li>
        <li>Unlimited number of circles</li>
        <li>Extended history of your circle members</li>
      </ul>
    </div>
    <div class="payment">
      <h4>{{ productTerms }} Days <span class="watermaloon">Free Trial</span>: $0</h4>
      <p class="payment__price">Then {{ productPrice }} per {{ productPeriod }}. Cancel anytime.<br/><span>Money back guaranteed!</span></p>
      <div class="payment__email">
        <p>Enter your email to get an invite</p>
        <input
          type="text"
          placeholder="Enter email"
          inputmode="email"
          :value="email"
          @keyup="validateEmailMask"
          @blur="updateSwiper">
        <div
          class="payment__email-error"
          v-show="error.status && email !== ''">
          {{ error.message }}
        </div>
        <div
          class="button relative"
          :class="{'disabled': error.status || email === ''}"
          @click="checkEmailUniqueness"
        >
          Continue
          <Spinner ref="spinner"/>
        </div>
      </div>
    </div>
    <div class="checkout">
      <div class="checkout__legal">
        <p class="checkout__copyright">
          {{ year }} @ All rights reserved. By continuing, you agree to our Privacy Policy and Terms of Service.
        </p>
        <ul>
          <li><a href="https://bpmob.com/safe24/support">Contact Us</a></li>          
          <li class="separator"></li>
          <li><a href="https://bpmob.com/safe24/privacy">Privacy Policy</a></li>     
          <li class="separator"></li>
          <li><a href="https://bpmob.com/safe24/terms">Terms of Service</a></li>
        </ul>
      </div>
    </div>
    <Overflow ref="overflow" @hidePayments="hidePayments"/>
    <PaymentsChoice ref="payments" :terms="productTerms" @toggleOverflow="toggleOverflow"/>

  </div>
</template>

<script>
import LandingServices from '@/services/LandingServices'
import Spinner from '@/components/Spinner'
import PaymentsChoice from './PaymentsChoice'
import Overflow from '@/components/Overflow'
export default {
  data() {
    return {
      email: '',
      error: {
        status: true
      },
      swipeDisabled: false,
      paypalEnabled: false
    }
  },
  props: {
    product: Object
  },
  components: {
    Spinner,
    PaymentsChoice,
    Overflow
  },
  computed: {    
    productPrice() {
      return `$${parseFloat(this.product?.unit_amount_decimal / 100).toFixed(2)}`
    },
    productPeriod() {
      const count = this.product?.recurring?.interval_count || 1
      const interval = this.product?.recurring?.interval || 'year'
      return (count > 1) ? `${count} ${interval}s` : interval
    },
    productTerms() {
      return `${this.product?.recurring?.trial_period_days}`
    },
    year() {
      return new Date().getFullYear()
    }
  },
  methods: {
    validateEmailMask(email) {
      this.email = email.target.value
      const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailIsValid = expression.test(String(this.email).toLowerCase())      
      this.error.status = !emailIsValid && this.email !== ''      
      this.error.message = ''
    },
    initMetrikaGoal() {
      setTimeout(() => {
        if (window.ym) {
          window.ym(process.env.VUE_APP_YANDEX_ID,'reachGoal','subscription_id')
        } else {
          this.initMetrikaGoal()
        }
      }, 100);
    },
    async checkEmailUniqueness() {
      await window.fbq('track', 'Submit Application')       
      if (!this.error.status) {
        this.toggleSpinner(true)
        this.userData = (await LandingServices.validateEmail(this.email).catch(() => null))?.data
        if (this.userData && this.userData.user_exists) {
          this.error = {status: true, message: 'Email is already taken'}  
        } else if (!this.userData){          
          this.error = {status: true, message: 'No connection'} 
        } else {          
          this.initMetrikaGoal()
          window.user_id = this.userData.user_id
          window.email = this.email
          this.toggleOverflow(true)
          this.togglePayments(true)
        }
        this.toggleSpinner(false)
      }
    },
    toggleSpinner(status) {
      this.$refs.spinner.toggleSpinner(status)
    },
    toggleOverflow(status) {
      this.$refs.overflow.toggleOverflow(status)
      this.swipeDisabled = status
    },
    togglePayments(status) {
      this.$refs.payments.togglePayments(status)
      this.swipeDisabled = status
    },
    hidePayments() {
      this.$refs.payments.hidePayments(true)
    },
    // iOS keyboard bug on hide
    updateSwiper(){
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    }
  },
  mounted() {
    if (this.$route.name === 'Retry') {
      if (window.email !== undefined) {
        this.email = window.email
        this.error.status = false
        this.toggleOverflow(true)
        this.togglePayments(true)
      } else {        
        this.$router.push({ name: 'Main'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>

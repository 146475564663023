<template>  
  <div
    class="overflow"
    :class="{'visible': isVisible}"
    @click="hideOverflow"
   ></div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0
    }
  },
  methods: {
    toggleOverflow(status) {
      this.isVisible = status
    },
    hideOverflow() {
      // this.toggleOverflow(false)
      this.$emit('hidePayments')
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  transition: all 0.15s;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
</style>